<template>
  <b-container fluid class="movement-route-detail-page py-6">
    <b-overlay :show="loading">
      <b-row>
        <b-col lg="3" xl="2">
          <div
            class="card card-custom gutter-b movement-route-detail-page__left-panel"
          >
            <div class="card-body p-0">
              <div class="movement-route-detail-page__left-panel__information">
                <b-container class="p-6">
                  <b-row>
                    <b-col>
                      <div class="text-muted">Mã lộ trình</div>
                      <div>{{ roadmapInfo.code }}</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div
                        class="movement-route-detail-page__left-panel__actions"
                      >
                        <b-button
                          size="sm"
                          class="btn-edit mr-4"
                          @click="editRoadMap"
                        >
                          <span class="svg-icon svg-icon-sm mr-0">
                            <inline-svg
                              class="svg-icon mr-0"
                              src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                            />
                          </span>
                        </b-button>
                        <span
                          class="status"
                          :class="getStatus(roadmapInfo.status)"
                        >
                          {{ getStatus(roadmapInfo.status) }}
                        </span>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-muted">Tên lộ trình</div>
                      <div>{{ roadmapInfo.name }}</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-muted">
                        Số {{ roadmapInfo.type === 1 ? 'ngày' : 'tuần' }}
                      </div>
                      <div
                        class="menu-icon svg-icon svg-icon-sm d-flex align-items-center"
                      >
                        <inline-svg
                          class="svg-icon mr-1"
                          src="/media/svg/icons/Neolex/Basic/calendar.svg"
                        />
                        {{
                          roadmapInfo.type === 1
                            ? roadmapInfo.day
                            : roadmapInfo.week
                        }}
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-muted">Mô tả</div>
                      <div>{{ roadmapInfo.description }}</div>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="9" xl="10">
          <template v-for="collapse in collapses">
            <CollapseSchedule
              :key="collapse.value"
              :id="collapse.value"
              :headers="headers"
              :agenda="collapse"
              @show="showModalActivity"
              @delete="reloadData"
            />
          </template>
        </b-col>
      </b-row>
    </b-overlay>
    <ModalActivity
      v-if="show"
      :showModal="show"
      :scheduleType="roadmapInfo.classify"
      :agenda="selectedAgenda"
      :activity-id="activityId"
      :type="modalType"
      @reset="resetModal"
      @reload-data="reloadData"
    />
    <ModalMovementRoute
      v-if="showModal"
      :type="modalType"
      :id="roadmapId"
      @reset="resetModalMovementRoute"
      @change-status="getData"
    />
  </b-container>
</template>
<script>
import { MODAL_TYPE, SCHEDULE_TYPES } from '../../../core/plugins/constants';
export default {
  name: 'OtherSchedule',
  components: {
    CollapseSchedule: () => import('./components/CollapseSchedule.vue'),
    ModalActivity: () => import('./components/ModalActivity.vue'),
    ModalMovementRoute: () => import('./components/Modal.vue'),
  },
  data() {
    return {
      collapses: [],
      selectedAgenda: null,
      activityId: null,
      show: false,
      loading: false,
      modalType: null,
      roadmapInfo: {
        code: null,
        name: null,
        day: null,
        tags: [],
        description: null,
        status: null,
        classify: null,
        type: null,
      },
      showModal: false,
    };
  },
  computed: {
    isCommonActivity() {
      return this.roadmapInfo?.classify === SCHEDULE_TYPES.COMMON;
    },
    isLesson() {
      return this.roadmapInfo?.classify === SCHEDULE_TYPES.LESSON;
    },
    isFreeActivity() {
      return this.roadmapInfo?.classify === SCHEDULE_TYPES.FREE;
    },
    isKpiActivity() {
      return this.roadmapInfo?.classify === SCHEDULE_TYPES.KPI;
    },
    headers() {
      if (this.isCommonActivity) {
        return [
          {
            label: 'Loại hoạt động',
            class: 'text-left',
            key: 'type',
            style: {
              width: '20%',
            },
          },
          {
            label: 'Tên hoạt động',
            class: 'text-left',
            key: 'name',
          },
          {
            label: '',
            class: 'text-center',
            key: 'actions',
            style: {
              width: '8%',
            },
          },
        ];
      }

      if (this.isLesson) {
        return [
          {
            label: 'Tên bài học',
            class: 'text-left',
            key: 'name',
          },
          {
            label: 'Loại bài học',
            class: 'text-left',
            key: 'type',
          },
          {
            label: 'Chủ đề',
            class: 'text-left',
            key: 'module',
          },
          {
            label: 'Cấp độ',
            class: 'text-center',
            key: 'level',
          },
          {
            label: '',
            class: 'text-center',
            key: 'actions',
            style: {
              width: '8%',
            },
          },
        ];
      }

      if (this.isKpiActivity) {
        return [
          {
            label: 'Loại hoạt động',
            class: 'text-left',
            key: 'name',
            style: {
              width: '20%',
            },
          },
          {
            label: 'Chi tiết',
            class: 'text-left',
            key: 'type',
          },
          {
            label: '',
            class: 'text-center',
            key: 'actions',
            style: {
              width: '8%',
            },
          },
        ];
      }

      return [
        {
          label: 'Tên hoạt động',
          class: 'text-left',
          key: 'name',
          style: {
            width: '20%',
          },
        },
        {
          label: 'Chi tiết',
          class: 'text-left',
          key: 'type',
        },
        {
          label: '',
          class: 'text-center',
          key: 'actions',
          style: {
            width: '8%',
          },
        },
      ];
    },
    roadmapId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data, meta, error } = await this.$api.get(
          `Agenda/${this.roadmapId}`,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }

        this.roadmapInfo = { ...data };
        this.roadmapInfo.code = data.code;
        this.roadmapInfo.name = data.name;
        this.roadmapInfo.day = data.day;
        this.roadmapInfo.description = data.description;

        this.roadmapInfo.status = data.status;
        this.roadmapInfo.classify = data.classify;
        this.roadmapInfo.type = data.type;

        this.setupData();
      } catch (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';
        default:
          return 'draft';
      }
    },
    setupData() {
      if (this.roadmapInfo.type === 1) {
        this.collapses = Array.from(
          { length: this.roadmapInfo.day },
          (_, i) => {
            return {
              text: i + 1,
              value: i + 1,
              type: this.roadmapInfo.type,
              classify: this.roadmapInfo.classify,
            };
          },
        );
        switch (this.roadmapInfo.classify) {
          case SCHEDULE_TYPES.FREE:
            this.collapses.forEach((el) => {
              el.agendas = this.roadmapInfo.agendaFrees.filter(
                (e) => e.day === el.value,
              );
              el.scheduleEndDay = this.roadmapInfo.day;
            });
            break;

          default:
            this.collapses.forEach((el) => {
              el.agendas = this.roadmapInfo.agendaKpis.filter(
                (e) => e.day === el.value,
              );
              el.scheduleEndDay = this.roadmapInfo.day;
            });
            break;
        }
        return;
      }
      this.collapses = Array.from({ length: this.roadmapInfo.week }, (_, i) => {
        return {
          text: i,
          value: i,
          type: this.roadmapInfo.type,
          classify: this.roadmapInfo.classify,
        };
      });

      switch (this.roadmapInfo.classify) {
        case SCHEDULE_TYPES.COMMON:
          this.collapses.forEach((el) => {
            el.agendas = this.roadmapInfo.agendaGenerals.filter(
              (e) => e.week === el.value,
            );
          });
          break;

        default:
          this.collapses.forEach((el) => {
            el.agendas = this.roadmapInfo.agendaLessons.filter(
              (e) => e.week === el.value,
            );
          });
          break;
      }
    },
    showModalActivity({ agenda, activityId, type }) {
      this.selectedAgenda = agenda;
      this.activityId = activityId;
      this.modalType = type;
      this.show = true;
    },
    resetModal() {
      this.show = false;
      this.selectedAgenda = null;
      this.activityId = null;
      this.modalType = null;
    },
    reloadData() {
      this.getData();
      this.resetModal();
    },
    editRoadMap() {
      this.modalType = MODAL_TYPE.EDIT;
      this.showModal = true;
      this.$store.commit(`context/setModal`, true);
    },
    resetModalMovementRoute() {
      this.showModal = false;
      this.modalType = null;
      this.$store.commit(`context/setModal`, false);
      this.getData();
    },
  },
};
</script>
<style lang="scss">
.movement-route-detail-page {
  .movement-route-detail-page__left-panel {
    height: 100%;

    .movement-route-detail-page__left-panel__title {
      span {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .movement-route-detail-page__left-panel__actions {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  .movement-route-detail-page__right-panel {
    height: 100%;
    padding: 12px;

    .movement-route-detail-page__right-panel__card-container {
      width: calc(100% / 7);
    }

    .movement-route-detail-page__right-panel__card {
      aspect-ratio: 1;

      .card-body {
        padding: 0;
        display: flex;
        flex-direction: column;
      }

      .movement-route-detail-page__right-panel__card__number {
        background-color: #008479;
        margin-right: 6px;
        color: #ffffff;
        font-weight: bold;
        flex: 0 0 42px;
      }

      .movement-route-detail-page__right-panel__card__title {
        color: #008479;
        flex-grow: 1;
        font-weight: bold;
        min-width: 0;
      }

      .movement-route-detail-page__right-panel__card__free-button {
        position: relative;
        background: #fdb913;
        color: white;
        font-size: 12px;
        text-align: center;
        display: inline-block;
        padding: 4px 12px;
      }

      .movement-route-detail-page__right-panel__card__free-button:before,
      .movement-route-detail-page__right-panel__card__free-button:after {
        content: '';
        position: absolute;
        top: 10px;
        height: 4.8px;
        width: 2.4px;
        border: 2px solid white;
      }

      .movement-route-detail-page__right-panel__card__free-button:before {
        left: -2px;
        border-radius: 0 4px 4px 0;
      }

      .movement-route-detail-page__right-panel__card__free-button:after {
        right: -2px;
        border-radius: 4px 0 0 4px;
      }

      .movement-route-detail-page__right-panel__card__action-dropdown {
        flex: 0 0 32px;
      }
    }
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis--line-clamp-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }

  &.draft {
    background: #888c9f;
  }
}

.btn-edit {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}

.icon-day-off {
  width: 80px;
  height: 60px;
}
</style>
